<script>
import FilesService from '@/service/files.service';
import { WebCam } from 'vue-web-cam';

export default {
  name: 'i-webcam-photo',

  components: {
    'vue-web-cam': WebCam,
  },

  props: {
    tenant: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      showModal: false,
      loading: false,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
    };
  },

  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },

  watch: {
    camera: function (id) {
      this.deviceId = id;
    },

    devices: function () {
      // Once we have a list select the first one
      // eslint-disable-next-line no-unused-vars
      const [first, ...tail] = this.devices;
      const userPreferenceWebcam = localStorage.getItem('user-preference-webcam');
      const preferenceDevice = this.devices.find((item) => item.deviceId === userPreferenceWebcam);
      if (preferenceDevice) {
        this.camera = preferenceDevice.deviceId;
        this.deviceId = preferenceDevice.deviceId;
      } else if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },

  created() {
    this.$on('create', this.create);
  },

  methods: {
    create() {
      this.img = false;
      this.showModal = true;
      this.$refs.webcam.start();
    },

    onCapture() {
      this.img = this.$refs.webcam.capture();
    },

    onStarted(stream) {
      console.log('On Started Event', stream);
    },

    onStopped(stream) {
      console.log('On Stopped Event', stream);
    },

    onStop() {
      this.$refs.webcam.stop();
    },

    onStart() {
      this.$refs.webcam.start();
    },

    onError(error) {
      console.log('On Error Event', error);
    },

    onCameras(cameras) {
      this.devices = cameras;
    },

    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      localStorage.setItem('user-preference-webcam', deviceId);
    },

    async upload() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('file', await fetch(this.img).then((res) => res.blob()));
        const res = await FilesService.upload(formData, { isTenant: this.tenant });
        this.$emit('onImage', res);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
      this.showModal = false;
    },

    clear() {
      this.img = '';
      this.onStop();
    },

    closeModal() {
      this.img = false;
      this.showModal = false;
      this.$refs.webcam.stop();
    },
  },
};
</script>

<template>
  <v-dialog id="modal-webcam-photo" v-model="showModal" persistent max-width="500px">
    <v-card>
      <!-- <v-card-title>
        <span class="text-h6">Tirar foto</span>
      </v-card-title> -->
      <v-card-text class="py-4 px-4">
        <div v-if="!img">
          <v-select
            v-model="camera"
            :items="devices"
            class="py-5"
            item-text="label"
            item-value="deviceId"
            label="Camera"
            color="white"
            hide-details="auto"
            outlined
            persistent-placeholder
            dense
          />
          <vue-web-cam
            ref="webcam"
            width="100%"
            height="100%"
            :device-id="deviceId"
            @started="onStarted"
            @stopped="onStopped"
            @error="onError"
            @cameras="onCameras"
            @camera-change="onCameraChange"
          />
        </div>
        <div v-else>
          <div class="my-1 border">
            <v-img :lazy-src="img" max-height="500" max-width="600" :src="img" />
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-btn v-show="!img" color="primary" @click="onCapture"
          ><v-icon class="mx-1"> mdi-camera </v-icon> Capturar</v-btn
        >
        <v-btn v-show="img" color="primary" outlined @click="clear">Tentar novamente</v-btn>
        <v-btn v-show="img" color="success" :loading="loading" @click="upload">Enviar</v-btn>
        <v-btn color="danger" @click="closeModal">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
