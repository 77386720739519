import api from './api';

class FilesService {
  upload(formData, { isTenant = false } = {}) {
    return api
      .post(`${isTenant ? '/tenant' : ''}/files/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  }

  async delete(id) {
    return api.delete(`/files/${id}`).then((res) => res.data);
  }
}

export default new FilesService();
