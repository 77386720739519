<script>
import IWebcamPhoto from '@/components/WebCamPhoto.vue';
import AvatarCropper from 'vue-avatar-cropper';

import 'cropperjs/dist/cropper.css';

export default {
  name: 'i-upload-avatar',

  components: { AvatarCropper, IWebcamPhoto },

  props: {
    value: {
      required: false,
    },

    size: {
      default: 250,
    },

    name: {
      default: 'file',
    },

    tenant: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      message: '',
      innerValue: '',
      innerValueInput: '',
      showMenu: false,
      showCropper: false,
      loading: false,
      avatarId: null,
      bearerToken: `Bearer ${localStorage.getItem('token')}`,
    };
  },

  computed: {
    apiSubmit() {
      return `${process.env.VUE_APP_API_URL}${this.tenant ? '/tenant' : ''}/files/upload`;
    },
  },

  watch: {
    value(val) {
      if (val) {
        if (val !== this.innerValue) {
          this.innerValue = val.url;
          this.avatarId = val.id;
        }
      } else {
        this.clear();
      }
    },
  },

  created() {
    this.clear();
    if (this.value) {
      this.setFile(this.value);
    }
  },

  methods: {
    setFile(file) {
      this.innerValue = file.url;
      this.avatarId = file.id;
    },

    handleUploading({ form, request, response }) {
      console.log('handleUploading', { form, request, response });
      this.loading = true;
      this.message = 'enviando...';
    },

    async handleUploaded({ form, request, response }) {
      console.log('handleUploaded', { form, request, response });
      const data = await response.json();
      this.setFile(data);
      this.message = 'foto enviada';
    },

    handleCompleted({ form, request, response }) {
      console.log('handleCompleted', { form, request, response });
      this.loading = false;
      this.message = 'foto salva';
    },

    handlerError({ message, type, context }) {
      console.error('handlerError', { message, type, context });
      this.loading = false;
      this.message = 'Oops! Algo deu errado...';
    },

    handleWebCamImage(file) {
      this.setFile(file);
    },

    clear() {
      this.innerValue = '';
      this.avatarId = null;
    },
  },
};
</script>

<template>
  <div class="position-relative avatar-upload">
    <v-badge avatar overlap top left color="transparent" :offset-y="size / 10">
      <template v-slot:badge>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="white" size="20" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
          </template>
          <span
            >Tamanho máximo do arquivo: <strong>100KB</strong>
            <br />
            Resolução máxima: <strong>600</strong> × <strong>1200</strong> pixels (L × A)
            <br />
            Resolução mínima: <strong>150</strong> × <strong>300</strong> pixels (L × A)
            <br />
            A altura não deve exceder duas vezes a largura
            <br
          /></span>
        </v-tooltip>
      </template>
      <v-badge avatar overlap bottom right color="backgroundSecond" :offset-x="size / 3" :offset-y="size / 10">
        <template v-slot:badge>
          <v-btn fab x-small color="backgroundSecond" @click="clear">
            <v-icon color="error"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <v-badge avatar overlap bottom right color="backgroundSecond" :offset-y="size / 2 - size / 35">
          <template v-slot:badge>
            <v-btn fab x-small color="backgroundSecond" @click="$refs.modalWebcamPhoto.$emit('create')">
              <v-icon color="green"> mdi-camera </v-icon>
            </v-btn>
          </template>
          <v-badge avatar overlap bottom right color="backgroundSecond" :offset-x="size / 7" :offset-y="size / 4">
            <template v-slot:badge>
              <v-btn fab dark x-small color="backgroundSecond" @click="showCropper = true">
                <v-icon color="primary"> mdi-cloud-upload </v-icon>
              </v-btn>
            </template>
            <v-avatar class="" color="primary" :size="size">
              <img
                v-if="innerValueInput || innerValue"
                v-auth-image="innerValue"
                :src="innerValueInput || innerValue"
                style="object-fit: cover"
              />
              <v-icon v-else :size="size / 2"> mdi-account </v-icon>
            </v-avatar>
          </v-badge>
        </v-badge>
      </v-badge>
    </v-badge>

    <avatar-cropper
      v-model="showCropper"
      with-credentials
      output-mime="image/jpeg"
      :output-quality="0.1"
      :upload-url="apiSubmit"
      :request-options="{ headers: { Authorization: bearerToken }, method: 'POST' }"
      :labels="{ submit: 'Enviar', cancel: 'Cancelar' }"
      @uploading="handleUploading"
      @uploaded="handleUploaded"
      @completed="handleCompleted"
      @error="handlerError"
    />
    <i-webcam-photo ref="modalWebcamPhoto" :tenant="tenant" @onImage="handleWebCamImage" />
  </div>
</template>

<style lang="scss">
.avatar-cropper-btn {
  background-color: #353f60 !important;
}
</style>
